.connect-modal-body {
  min-height: 65vh;
  .modal-row {
    min-height: 65vh; }
  .left-side {
    background-color: rgba(grey, .1);
    padding-top: 4.5rem; }
  .right-side {
    background-color: rgba(white, .1);
    padding-top: 4.5rem; }
  .nav-button {
    &:hover, &.active {
      background-color: rgba(white, .6); } } }
