.whitelistcontent {
  &__noaccount, &__email-input {
    margin-top: calc(27vh - #{$navbar-height}); }
  &__paragraph {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto; }
  &__popover {
    .popover {
      border: none;
      .arrow .popover-arrow {
        border: none; } } }
  &__already-on-allowlist {
    margin-top: calc(23vh - #{$navbar-height}); } }
