.navigationbar {
  z-index: 999;
  height: $navbar-height;
  position: fixed;
  width: 100%;
  transition: background-color 500ms linear;
  &__navbar {
    height: 100%; }
  &__logo {
    position: absolute;
    left: 45%;
    right: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  &__user {
    @include media-breakpoint-up(sm) {
      order: 3; }
    border-left: 1px solid $navbar-dark-color; }
  &--sticky {
    background-color: $dark; }
  .navbar-collapse {
    &.show, &.collapsing {
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-top: 0;
        margin-bottom: 0; } } } }
.navplaceholder {
  height: $navbar-height;
  width: 100%;
  background-color: $dark;
  position: fixed;
  top: 0;
  z-index: 990; }
.navplaceholder:after {
  position: relative;
  height: $navbar-height; }
.recipedisplay {
  padding-top: 8rem; }

.navbar-dark .navbar-nav .dropdown-menu.show .nav-link {
  color: $body-color; }

.navbar-collapse {
  @include media-breakpoint-down(xs) {
    margin: 0.5rem -1rem;
    background-color: $white;
    padding: 1rem;
    .navbar-nav {
      .nav-link {
        color: rgba(0, 0, 0, 0.5); }
      .show > .nav-link, .active > .nav-link, .nav-link.show, .nav-link.active, .nav-link:focus {
        color: $dark; } } } }

.navbar-nav .dropdown-menu .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
