$arctic-blue: #001A72;
$arctic-blue-sky: #0032A0;
$arctic-bg-gradient: linear-gradient(98.77deg, #0032A0 32.2%, #001A72 100%);
$polar-blue: #D9E6F4;
$eerie-black: #222222;
$grey: #f1f1f1;
$white: #FFFFFF;
$fibra-alt : 'fibra-alt';
$fibra: 'fibra';

$letter-spacing-xs: 0.01em;
$letter-spacing-s: 0.02em;
$letter-spacing-m: 0.05em;
$letter-spacing-l: 0.1em;
$letter-spacing-xl: 0.13em;
$letter-spacing-xxl: 0.14em;

$letter-spacing-big: 0.2em;
$letter-spacing-button: 0.23em;

// already changed in variales file
// $border-radius-l: 10px
// $border-radius-s: 6px
