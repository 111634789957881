.whitelist {
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - #{$navbar-height});
  &__bgvector {
    position: absolute;
    z-index: -1;
    &__1 {
      width: 57vw;
      left: -11vw;
      top: calc(30vh - #{$navbar-height}); }
    &__2 {
      width: 32vw;
      right: -7vw;
      top: calc(-2vh - #{$navbar-height}); } } }

