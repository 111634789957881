// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

//fonts
@include fontFace('fibra', '/assets/fonts/Fibra-Regular', normal , 400);
@include fontFace('fibra', '/assets/fonts/Fibra-Bold', normal, 800);
@include fontFace('fibra-alt', '/assets/fonts/FibraAlt-Regular', normal, 400);
@include fontFace('fibra-alt', '/assets/fonts/FibraAlt-Bold', normal, 800);

// theme
@import "styles/prebootstrapvariables";
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/variables";

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";

// stylesheets containers
@import "styles/containers/Root";
@import "styles/containers/App";
@import "styles/containers/Landingpage";
@import "styles/containers/Dashboard";
@import "styles/containers/Account";
@import "styles/containers/Home";
@import "styles/containers/FourOFour";
@import "styles/containers/Whitelist";
@import "styles/containers/TokenDetail";
@import "styles/containers/EventDetail";

//stylesheets components
@import "components/About/About";
@import "components/FieldInput/FieldInput";
@import "components/FileInput/FileInput";
@import "components/LoadingContent/LoadingContent";
@import "components/Navbar/Navbar";
@import "components/Register/Register";
@import "components/LandingpageHeroImage/LandingpageHeroImage";
@import "components/OnBoardingContent/OnBoardingContent";
@import "components/BowlineWallet/BowlineWallet";
@import "components/ConnectWalletButton/AuthConnectButton";
@import "components/ConnectWallet/ConnectWallet";
@import "components/ProjectOnboarding/ProjectOnboarding";
@import "components/WhitelistContent/WhitelistContent";
@import "components/ArcticNavbar/ArcticNavbar";
@import "components/Token/TokenCard/TokenCard";


body {
  margin: 0;
  padding: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h1, h2, h3, .h1, .h2, .h3 {
  font-weight: 800; }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }

.btn-primary {
  border-radius: $border-radius-sm !important;
  background: $arctic-bg-gradient;
  &:hover {
    opacity: .875; } }

.button-width {
  @include media-breakpoint-up(sm) {
    width: 290px !important; } }

.cursor-pointer {
  cursor: pointer; }

.bg-gradient {
  background: $arctic-bg-gradient !important; }

.active {
  color: $arctic-blue !important;
  font-weight: bold !important; }

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  @include media-breakpoint-up(sm) {
    padding-left: 3.75rem;
    padding-right: 3.75rem; } }

.font-alt {
  font-family: "fibra-alt"; }
