.arcticnavbar {
  &__navlink {
    position: relative;
    &.disabled {
      pointer-events: none;
      &::before {
        content: "";
        position: absolute;
        top: -0.25rem;
        right: -0.5rem;
        background-color: $danger;
        border-radius: 50%;
        padding: 0.25rem; } } } }
